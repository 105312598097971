import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const ContainerSideBar = styled.div`
    flex: 0 0 275px;
    width: 275px;
    background: rgb(8, 88, 157);
    color: white;
    position: relative;
`;

export const SubContainer = styled.div`
    z-index: 99;
    background: rgb(8, 88, 157);
    height: 100vh;
`;

export const Header = styled.div`
    margin: auto;
    textAlign: center;
    padding: 70px;
`;

export const SideBarMenu = styled.div`
    background: rgb(8, 88, 157);
    color: white;
`;

export const ContainerLink = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const SubContCotizador = styled.div`
    display: flex;
    flex-direction: column;
`;
export const LinkSideBar = styled(NavLink)`
    background-color: #08589D;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.25;
    padding: 15px 20px 15px 56px;
    white-space: normal;
    width: 100%;
    font-weight: 600;
    &:hover {
        text-decoration: none !important;
        color: #6c757d;
    }
    &.active {
    color: white;
    background-color: #ffffff38;
    border-radius: 5px;
  }
`;

export const ContCotizacion = styled.div`
    background-color: #08589D;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 1.25;
    padding: 15px 20px 15px 56px;
    white-space: normal;
    width: 100%;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        text-decoration: none !important;
        color: #6c757d;
    }
    &.active {
    color: white;
    background-color: #ffffff38;
    border-radius: 5px;
  }
`;
export const Nota = styled.img`
    width: 1.7rem;
`