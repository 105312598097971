import React, { useState, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { Container,
    Wrapper,
    Hero,
    Title,
    Subtitle,
    StyledForm,
    UserDetails,
    InputBox,
    InputTag,
    Details,
    Nota,
    ButtonGroup,
    ButtonBox2,
    InputTypeSend,
    ContButton,
    TextBtn,
    WrapperText,
    TextButton,
    WrapperCosolicitante,
    InputDescripcion,
    InputTexArea,
    SelecTag22,
    TextBtnDelete, } from './styledSolicitud';

import "react-datepicker/dist/react-datepicker.css";

import { HiDocumentAdd } from 'react-icons/hi';
import { MdDeleteForever } from 'react-icons/md';
// import { IoMdAdd } from "@react-icons/all-files/Io/IoMdAdd";

// import Logo from '../images/nota.svg';
import Solicitudes from '../../images/icons/garantias.svg'
import { postGarantia } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';

import '../../App.css';
import { BodyContainerAllComponent } from '../Admin/AdminHome/AdminHome';
import ImageUpload from '../ImageUpload/ImageUpload';
import { useNavigate } from 'react-router-dom';
import { GeoGobierno, GeoGobiernoLocalidades } from '../GeoGobierno/GeoGobierno';
import { SideBar } from '../SideBar/SideBar';


const CrearSolicitud = () => {

    const provincias = useSelector(state => state.provinciaLocalidadesReducer);
    const provinciasCosolicitante = useSelector(state => state.provinciaLocalidadesCosolicitanteReducer);
    // const resPostGarantia = useSelector(state => state.resPostGarantia.data)
    const [datoProvincias, setDatoProvincias] = useState(null);
    const [datoLocalidad, setDatoLocalidad] = useState(null)
    const navigate = useNavigate()

    //PROVINCIA 
    // const [provincia, setProvincia] = useState({
    //     name: ""
    // })
    const [provinciaCoso, setProvinciaCoso] = useState({
        name: ""
    })

    const [provinciaSelect, setProvinciaSelect] = useState({
        provincia_select: 0
    });

    //LOCALIDAD
    const [localidadSelect, setLocalidadSelect] = useState({
        localidad_select: 0
    });

    //PROVINCIA COSOLICITANTE


    const [provinciaCosolicitante, setProvinciaCosolicitante] = useState({
        provincia_select_Cosolicitante: 0
    });

    //LOCALIDAD COSOLICITANTE
    const [localidadCosolicitante, setLocalidadCosolicitante] = useState({
        localidad_select_Cosolicitante: 0
    });

    const dispatch = useDispatch();

    // Modal
    const [stateModal, changeStateModal] = useState(false);

    // Collapse
    const [show, setShow] = useState(false);
    const handleToggle = () => setShow(!show);

    // Campos
    const [campos, setCampos] = useState({
        nombre: '',
        dni: '',
        email: '',
        telefono: '',
        domicilio: '',

        //PROVINCIAS & LOCALIDADES

        montoAlquiler: '',
        montoExpensas: '',
        valorGarantia: '',
        iniContrato: '',
        finContrato: '',
        valorGarantia: '',

        // nombreCoSolicitante: '',
        // emailCoSolicitante: '',
        // dniCoSolicitante: '',
        // telefonoCoSolicitante: '',
        // domicilioCoSolicitante: '',

        //CAMPOS NUEVOS
        nacionalidadCoSolicitante: '',

        nacionalidad: '',
        inmoMartillero: '',
        direccionInmueble: '',

        nombrePropietario: '',
        dniPropietario: '',
        emailPropietario: '',

        tipoAlquiler: '',
        modoPago: '',
        metodoPago: '',
        domicilioAlquiler: '',
        observaciones: ''
    })

    const [stateFrente, setStateFrente] = useState({
        dniFrente: '',
        dniDorso: '',
        respaldo: []
    });

    const [documentacionCoS, setDocumentacionCoS] = useState({
        dniFrenteCoSolicitante: '',
        dniDorsoCoSolicitante: '',
        respaldoCoSolicitante: []
    });

    const onChangeRespaldo = (event) => {
        for (var i = 0; i < event.target.files.length; i++) {
            stateFrente.respaldo.push(event.target.files[i]);
        }
    }

    const onChangeRespaldoCoSo = (event) => {

        for (var i = 0; i < event.target.files.length; i++) {
            documentacionCoS.respaldoCoSolicitante.push(event.target.files[i]);
        }

    }

    const handleCampos = (event) => {
        setCampos({
            ...campos,
            [event.target.name]: event.target.value
        })
    }

    const onChange = (e) => {
        setStateFrente({
            ...stateFrente,
            [e.target.name]: e.target.files[0]
        })
    }

    const onChangeCoS = (e) => {
        setDocumentacionCoS({
            ...documentacionCoS,
            [e.target.name]: e.target.files[0]
        })
    }



    //COSOLICITANTE
    if (provinciasCosolicitante?.data != null) {
        var itemsCosoli = [];
        const pu = provinciasCosolicitante.data;
        for (var i = 0; i < pu.length; i++) {
            itemsCosoli.push(<option value={[i]} > {pu[i].name} </option>)
        }
    }

    const changeProvinciaCosolicitante = (event) => {
        setProvinciaCosolicitante({
            ...provinciaCosolicitante,
            [event.target.name]: event.target.value
        });
    }

    if (provincias?.data != null) {
        const data = provincias.data[provinciaCosolicitante.provincia_select_Cosolicitante];
        var allLocalidadesCosoli = [];
        for (var i = 0; i < data.localidades.length; i++) {
            allLocalidadesCosoli.push(<option value={data.localidades[i].name} > {data.localidades[i].name} </option>)
        }
    }

    const changeLocalidadCosolicitante = (event) => {
        setLocalidadCosolicitante({
            ...localidadCosolicitante,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        if (provincias?.data != null) {
            setProvinciaCoso({ name: provincias.data[provinciaCosolicitante.provincia_select_Cosolicitante].name });
            setLocalidadCosolicitante({ localidad_select_Cosolicitante: provincias.data[provinciaCosolicitante.provincia_select_Cosolicitante].localidades[0].name })

        }
    }, [provinciaCosolicitante]);


    //SOLICITANTE
    if (provincias?.data != null) {
        var items = [];
        const pu = provincias.data;
        for (var i = 0; i < pu.length; i++) {
            items.push(<option value={[i]} > {pu[i].name} </option>)
        }
    }

    const changeProvincia = (event) => {
        setProvinciaSelect({
            ...provinciaSelect,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        if (provincias?.data != null) {
            setProvincia({ name: provincias.data[provinciaSelect.provincia_select].name });
            setLocalidadSelect({ localidad_select: provincias.data[provinciaSelect.provincia_select].localidades[0].name })

        }
    }, [provinciaSelect]);

    if (provincias?.data != null) {
        const data = provincias.data[provinciaSelect.provincia_select];
        var allLocalidades = [];
        for (var i = 0; i < data.localidades.length; i++) {
            allLocalidades.push(<option value={data.localidades[i].name} > {data.localidades[i].name} </option>)
        }
    }

    const changeLocalidad = (event) => {
        setLocalidadSelect({
            ...localidadSelect,
            [event.target.name]: event.target.value
        });
    }


    const {
        register,
        reset,
        control,
        formState: { errors, isValid },
        handleSubmit
    } = useForm({
        mode: "onChange"
    });


    const onSubmit = async (data) => {
        let formData = new FormData();
        if (stateFrente.respaldo.length > 0) {
            for (let i = 0; i < stateFrente.respaldo.length; i++) {
                formData.append('imagenRespaldo[' + i + ']', stateFrente.respaldo[i])
            }
        }
        if (documentacionCoS.respaldoCoSolicitante.length > 0) {
            for (let i = 0; i < documentacionCoS.respaldoCoSolicitante.length; i++) {
                formData.append('imagenRespaldoCoSo[' + i + ']', documentacionCoS.respaldoCoSolicitante[i])
            }
        }
        formData.append("data", JSON.stringify(data));
        formData.append("imageFrente", stateFrente.dniFrente);
        formData.append("imageDorso", stateFrente.dniDorso);
        formData.append("imageDniFrenteCoSolicitante", documentacionCoS.dniFrenteCoSolicitante);
        formData.append("imageDniDorsoCoSolicitante", documentacionCoS.dniDorsoCoSolicitante);
        await dispatch(postGarantia(formData));
        setStateFrente({
            dniFrente: '',
            dniDorso: '',
            respaldo: []
        })
        setDocumentacionCoS({
            dniFrenteCoSolicitante: '',
            dniDorsoCoSolicitante: '',
            respaldoCoSolicitante: []
        })
        navigate('/solicitudes_generales');

    };


    const [filtroProvincia, setFiltroProvincia] = useState("");
    const [filtroLocalidad, setFiltroLocalidad] = useState("");

    var provinciasArray = [];
    if (provincias != null) {
        for (var i = 0; i < provincias.length; i++) {
            provinciasArray.push(
                <option value={provincias[i].value}>{provincias[i].nombre}</option>
            );
        }
    }



    const [mostrarImagen, setMostrarImagen] = useState({
        dniFrente: "https://res.cloudinary.com/trustfund2022/image/upload/v1676643019/CRM_TRUST%20FUND/Promociones/DNI-01_px03ww.jpg",
        dniDorso: "https://res.cloudinary.com/trustfund2022/image/upload/v1676643020/CRM_TRUST%20FUND/Promociones/DNI-02_mo6p3g.jpg",
        respaldo: "https://res.cloudinary.com/trustfund2022/image/upload/v1676646286/CRM_TRUST%20FUND/Promociones/respaldo_shii6u.jpg"
    });
    const [mostrarImagenCoSolicitante, setMostrarImagenCoSolicitante] = useState({
        dniFrenteCoSolicitante: "https://res.cloudinary.com/trustfund2022/image/upload/v1676643019/CRM_TRUST%20FUND/Promociones/DNI-01_px03ww.jpg",
        dniDorsoCoSolicitante: "https://res.cloudinary.com/trustfund2022/image/upload/v1676643020/CRM_TRUST%20FUND/Promociones/DNI-02_mo6p3g.jpg",
        respaldoCoSolicitante: "https://res.cloudinary.com/trustfund2022/image/upload/v1676646286/CRM_TRUST%20FUND/Promociones/respaldo_shii6u.jpg"
    });

    const capturarImagenCoSolicitante = (e, tipoImagen) => {
        if (e.target.files[0]) {
            setDocumentacionCoS(prevState => ({
                ...prevState,
                [tipoImagen]: e.target.files[0]
            }));
            const readerImagen = new FileReader();
            readerImagen.addEventListener("load", () => {
                setMostrarImagenCoSolicitante(prevState => ({
                    ...prevState,
                    [tipoImagen]: readerImagen.result
                }));
            });
            readerImagen.readAsDataURL(e.target.files[0]);
        }
    };

    const capturarImagen = (e, tipoImagen) => {
        if (e.target.files[0]) {
            setStateFrente(prevState => ({
                ...prevState,
                [tipoImagen]: e.target.files[0]
            }));
            const readerImagen = new FileReader();
            readerImagen.addEventListener("load", () => {
                setMostrarImagen(prevState => ({
                    ...prevState,
                    [tipoImagen]: readerImagen.result
                }));
            });
            readerImagen.readAsDataURL(e.target.files[0]);
        }
    };

    useEffect(() => {
        const setDefaults = obj => {
            if (!obj.dniFrente) {
                obj.dniFrente = "https://res.cloudinary.com/trustfund2022/image/upload/v1676643019/CRM_TRUST%20FUND/Promociones/DNI-01_px03ww.jpg";
            }
            if (!obj.dniDorso) {
                obj.dniDorso = "https://res.cloudinary.com/trustfund2022/image/upload/v1676643020/CRM_TRUST%20FUND/Promociones/DNI-02_mo6p3g.jpg";
            }
            if (!obj.respaldo) {
                obj.respaldo = "https://res.cloudinary.com/trustfund2022/image/upload/v1676646286/CRM_TRUST%20FUND/Promociones/respaldo_shii6u.jpg";
            }
        };

        setDefaults(mostrarImagen);
    }, []);



    useEffect(() => {
        handleChangeLocalidad("")
        handleChangeProvincia("")
    }, []);


    const [provincia, setProvincia] = useState("Buenos Aires");
    const onChangeProvincia = (name) => {
        setProvincia(name);
    };

    useEffect(() => {
        if (provincia != null) {
            const traerLocalidades = async () => {
                try {
                    const data = await GeoGobiernoLocalidades(provincia)
                    setDatoLocalidad(data);
                } catch (err) {
                    console.error(err);
                }
            }

            traerLocalidades()
        }

    }, [provincia]);



    const traerPronvincias = async () => {
        try {
            const data = await GeoGobierno()
            setDatoProvincias(data);
        } catch (err) {
            console.error(err);
        }
    }

    useEffect(() => {
        traerPronvincias();
        // setDatoProvincias("Buenos Aires")
    }, []);

    var provinciasArregloVacio = [];

    if (datoProvincias != null) {
        for (var i = 0; i < datoProvincias.provincias.length; i++) {
            provinciasArregloVacio.push(

                <option
                    value={datoProvincias.provincias[i].nombre}
                    selected={datoProvincias.provincias[i].nombre == "Buenos Aires"}>
                    {datoProvincias.provincias[i].nombre}
                </option>

            );
        }
    }

    var LocalidadArregloVacio = [];

    if (datoLocalidad != null) {
        let arregloAux = []
        for (var i = 0; i < datoLocalidad.localidades.length; i++) {
            arregloAux.push(datoLocalidad.localidades[i].nombre);
        }
        arregloAux = arregloAux.sort();
        for (var i = 0; i < arregloAux.length; i++) {
            LocalidadArregloVacio.push(
                <option
                    value={arregloAux[i]}>
                    {arregloAux[i]}
                </option>
            );
        }
    }


    const handleChangeProvincia = (e) => {
        setFiltroProvincia(e);
    };

    const handleChangeLocalidad = (e) => {
        setFiltroLocalidad(e);
    };


    // const handleTypeSelect = (e) => {
    //     setSelectedOption(e.value);
    // };


    // para resuite select

    // if (provincia != null && provincia != "") {
    //     LocalidadArregloVacio = Localidades.sort((a, b) => {
    //         const n = a.Localidad.localeCompare(b.Localidad);
    //         return n === 0 && a.Localidad !== n.Localidad
    //             ? b.Localidad.localeCompare(a)
    //             : n;
    //     })
    //         .filter((localidad) => {
    //             return localidad.Provincia === provincia;
    //         })
    //         .map((option) => ({ label: option.Localidad, value: option.Localidad }));
    // }


    // if (datoProvincias != null) {
    //     var provinciasArregloVacio = []
    //     // const lc = misVideosComprados.data.categorias;
    //     for (var i = 0; i < datoProvincias.provincias.length; i++) {
    //         provinciasArregloVacio.push({
    //             value: datoProvincias.provincias[i].nombre,
    //             label: datoProvincias.provincias[i].nombre,
    //         });
    //     }
    // }

    // var LocalidadArregloVacio = [];

    // if (datoLocalidad != null) {
    //     // var LocalidadArregloVacio = [];
    //     // const lc = misVideosComprados.data.categorias;
    //     for (var i = 0; i < datoLocalidad.localidades.length; i++) {
    //         LocalidadArregloVacio.push({
    //             value: datoLocalidad.localidades[i].nombre,
    //             label: datoLocalidad.localidades[i].nombre,
    //         });
    //     }

    // }

    //agregar objeto al array data de formdata

    const { fields, append, remove, prepend } = useFieldArray({
        control,
        name: "coSolicitante"
    });



    return (
        <>
            <SideBar />
            <BodyContainerAllComponent>
                <Container>
                    <Wrapper>
                        <Hero>
                            <Nota style={{ paddingTop: '12px' }} src={Solicitudes} alt='' />
                            <Title>Generar Solicitud de Garantía</Title>
                        </Hero>
                        <WrapperText>
                            {/* <ContButton>
                                <TextBtn style={{ marginRight: '35px' }} onClick={() => {
                                    append(); SumaUnCoSolitiante();
                                }} > Cuento con un Co-Solicitante <HiDocumentAdd fontSize={30} color='white' /> </TextBtn>
                            </ContButton> */}
                            {/* <Animation.Collapse in={show}> */}

                            {/* </Animation.Collapse> */}
                            <div >
                                <ul>
                                    {fields.map((item, index) => {
                                        return (
                                            <WrapperCosolicitante key={item.id}>
                                                <Subtitle> <li type="square">Datos del Co-Solicitante : </li></Subtitle>
                                                <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                                    <InputBox>
                                                        <Details> Nombre/s y Apellido/s: </Details>
                                                        <InputTag type='text' name='nombreCoSolicitante'  {...register(`coSolicitante.${index}.nombreCoSolicitante`)}></InputTag>
                                                    </InputBox>
                                                    <InputBox>
                                                        <Details> Correo Electrónico: </Details>
                                                        <InputTag type='email' name='emailCoSolicitante'  {...register(`coSolicitante.${index}.emailCoSolicitante`)}></InputTag>
                                                    </InputBox>
                                                    <InputBox>
                                                        <Details> DNI: </Details>
                                                        <InputTag type='number' onWheel={(e) => e.target.blur()} name='dniCoSolicitante'  {...register(`coSolicitante.${index}.dniCoSolicitante`)}></InputTag>
                                                    </InputBox>

                                                    <InputBox>
                                                        <Details> Nacionalidad: </Details>
                                                        <InputTag type='text' name='nacionalidadCosolicitante'  {...register(`coSolicitante.${index}.nacionalidadCosolicitante`)}></InputTag>
                                                    </InputBox>

                                                    <InputBox>
                                                        <Details> Teléfono: </Details>
                                                        <InputTag type='number' onWheel={(e) => e.target.blur()} name='telefonoCoSolicitante'  {...register(`coSolicitante.${index}.telefonoCoSolicitante`)}></InputTag>
                                                    </InputBox>
                                                    <InputBox>
                                                        <Details> Domicilio: </Details>
                                                        <InputTag type='text' name='domicilioCoSolicitante'  {...register(`coSolicitante.${index}.domicilioCoSolicitante`)}></InputTag>
                                                    </InputBox>

                                                    {/* PROVINCIAS & LOCALIDADES */}
                                                    <InputBox>
                                                        <Details> Provincia </Details>
                                                        {/* <SelecTag2   > {itemsCosoli} </SelecTag2>  */}
                                                        <SelecTag22
                                                            name="provinciaCosolicitante"
                                                            {...register(`coSolicitante.${index}.provinciaCosolicitante`)}
                                                            onChange={(e) => onChangeProvincia(e.target.value)}
                                                        >
                                                            <option value="" selected disabled hidden>
                                                                Provincia
                                                            </option>
                                                            {provinciasArregloVacio}
                                                        </SelecTag22>
                                                    </InputBox>
                                                    <InputBox>
                                                        <Details> Localidad </Details>
                                                        {/* <SelecTag2   > {allLocalidadesCosoli} </SelecTag2> */}
                                                        <SelecTag22
                                                            name="localidadCosolicitante"
                                                            {...register(`coSolicitante.${index}.localidadCosolicitante`)}
                                                        >
                                                            <option value="" selected disabled hidden>
                                                                Localidad
                                                            </option>

                                                            {LocalidadArregloVacio}
                                                        </SelecTag22>
                                                    </InputBox>
                                                    {/* <InputBox>
                                                                              <Details>Situación laboral:</Details>
                                                                              <InputTag type='text' name='sitLaboralCoSolicitante' onChange={handleCampos}></InputTag>
                                                                          </InputBox> */}
                                                </UserDetails>

                                                <Subtitle> <li type="square"> Documentación del Co-Solicitante: </li></Subtitle>
                                                <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                                    <ImageUpload
                                                        label="Adjuntar DNI Frente:"
                                                        id="dniFrenteCoSolicitante"
                                                        typeImagen="dniFrenteCoSolicitante"
                                                        mostrarImagen={mostrarImagenCoSolicitante}
                                                        capturarImagen={capturarImagenCoSolicitante}
                                                    />
                                                    <ImageUpload
                                                        label="Adjuntar DNI Dorso:"
                                                        id="dniDorsoCoSolicitante"
                                                        typeImagen="dniDorsoCoSolicitante"
                                                        mostrarImagen={mostrarImagenCoSolicitante}
                                                        capturarImagen={capturarImagenCoSolicitante}
                                                    />
                                                    <ImageUpload
                                                        label="Adjuntar Datos de Respaldo:"
                                                        id="respaldoCoSolicitante"
                                                        typeImagen="respaldoCoSolicitante"
                                                        mostrarImagen={mostrarImagenCoSolicitante}
                                                        capturarImagen={capturarImagenCoSolicitante}
                                                    />
                                                </UserDetails>
                                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                                                    {/* <TextBtn style={{ backgroundColor: 'red' }} onClick={onRemove}>Eliminar</TextBtn> */}
                                                    <TextBtnDelete type="button" onClick={() => remove(index)}>
                                                        <MdDeleteForever fontSize={30} />
                                                    </TextBtnDelete>
                                                </div>
                                                <br></br>
                                                <hr></hr>
                                            </WrapperCosolicitante>

                                        );
                                    })}
                                </ul>
                                {/* <TextBtn onClick={handleOnAdd}>Agregar</TextBtn> */}
                            </div>
                        </WrapperText>
                        <Subtitle> <li type="square"> Datos del Solicitante: </li></Subtitle>
                        <StyledForm onSubmit={handleSubmit(onSubmit)}>
                            <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                <InputBox>
                                    <Details>Nombre/s y Apellido/s:</Details>
                                    <InputTag type='text' name='nombre'  {...register("nombre", { required: true })} ></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>DNI:</Details>
                                    <InputTag type='number' onWheel={(e) => e.target.blur()} name='dni'  {...register("dni", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details> Nacionalidad: </Details>
                                    <InputTag type='text' name='nacionalidad' {...register("nacionalidad", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Email:</Details>
                                    <InputTag type='email' name='email' {...register("email", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Teléfono:</Details>
                                    <InputTag type='number' onWheel={(e) => e.target.blur()} name='telefono' {...register("telefono", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details> Domicilio: </Details>
                                    <InputTag type='text' name='domicilio'{...register("domicilio", { required: true })}></InputTag>
                                </InputBox>

                                {/* PROVINCIAS & LOCALIDADES */}
                                <InputBox>
                                    <Details> Provincia </Details>
                                    {/* <Controller
                                control={control}
                                rules={{
                                  required: true,
                                }}
                                render={({ field: { ref, ...field } }) => (
                                    <SelecTag2 
                                    size="md" 
                                    placeholder="" 
                                    data={provinciasArregloVacio}    
                                    value={filtroProvincia}          
                                    inputRef={ref}
                                    onChange={(value, e) => {
                                        field.onChange(onChangeProvincia(value));
                                        field.onChange(value);
                                      }}
                                      placement="auto"
                                    onClean={(value, e) => handleChangeProvincia("")}
                                    /> )}
                                    name="provincia"
                                  /> */}
                                    <SelecTag22
                                        {...register("provincia", {
                                            required: true,
                                        })}
                                        onChange={(e) => onChangeProvincia(e.target.value)}
                                    >
                                        <option value="" selected disabled hidden>
                                            Provincia
                                        </option>
                                        {provinciasArregloVacio}
                                    </SelecTag22>
                                </InputBox>
                                <InputBox>
                                    <Details> Localidad </Details>
                                    {/* <Controller
                                name="localidad"
                                control={control}
                                rules={{ required: true }}
                                render={({ field: { ref, ...field } }) => (
                                    <SelecTag2 
                                    {...field}
                                    size="md" 
                                    placeholder=""
                                    placement="auto" 
                                    data={LocalidadArregloVacio} 
                                    value={filtroLocalidad}
                                    onChange={(value, e) => handleChangeLocalidad(value)}
                                    onClean={(value, e) => handleChangeLocalidad("")}
                                    inputRef={ref}
                                    />
                                    )}
                                    /> */}
                                    <SelecTag22
                                        {...register("localidad", {
                                            required: true,
                                        })}
                                    >
                                        <option value="" selected disabled hidden>
                                            Localidad
                                        </option>

                                        {LocalidadArregloVacio}
                                    </SelecTag22>
                                </InputBox>
                            </UserDetails>

                            <br></br>
                            <hr></hr>

                            <Subtitle> <li type="square"> Datos del Inmueble: </li> </Subtitle>
                            <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                <InputBox>
                                    <Details><b>Inmobiliaria / Martillero:</b></Details>
                                    <InputTag type="text" name="inmoMartillero" {...register("inmoMartillero", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details><b>Dirección alquiler:</b></Details>
                                    <InputTag type="text" name="direccionInmueble" {...register("direccionInmueble", { required: true })}></InputTag>
                                </InputBox>
                            </UserDetails>

                            <br></br>
                            <hr></hr>

                            <Subtitle> <li type="square"> Propietario: </li> </Subtitle>
                            <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                <InputBox>
                                    <Details><b>Nombre/s y Apellido/s:</b></Details>
                                    <InputTag type="text" name="nombrePropietario" {...register("nombrePropietario", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details><b>DNI:</b></Details>
                                    <InputTag type="number" onWheel={(e) => e.target.blur()} name="dniPropietario" {...register("dniPropietario", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details><b>Email:</b></Details>
                                    <InputTag type="email" name="emailPropietario" {...register("emailPropietario", { required: true })}></InputTag>
                                </InputBox>
                            </UserDetails>

                            <br></br>
                            <hr></hr>

                            <Subtitle> <li type="square"> Alquiler: </li></Subtitle>
                            <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                <InputBox>
                                    <Details>Fecha de inicio del contrato:</Details>
                                    <InputTag type='date' name='iniContrato' {...register("iniContrato", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Fecha fin de contrato:</Details>
                                    <InputTag type='date' name='finContrato'  {...register("finContrato", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Domicilio de alquiler:</Details>
                                    <InputTag type='text' name='domicilioAlquiler'  {...register("domicilioAlquiler", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Valor de la garantía:</Details>
                                    <InputTag type='number' onWheel={(e) => e.target.blur()} name='valorGarantia'  {...register("valorGarantia", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Monto Alquiler:</Details>
                                    <InputTag type='number' name='montoAlquiler'  {...register("montoAlquiler", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Monto Expensas:</Details>
                                    <InputTag type='number' onWheel={(e) => e.target.blur()} name='montoExpensas' {...register("montoExpensas", { required: true })}></InputTag>
                                </InputBox>

                                <InputBox>
                                    <Details>Tipo de alquiler:</Details>
                                    <InputTag type='text' name='tipoAlquiler' placeholder='Comercial / Vivienda' {...register("tipoAlquiler", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Modo de pago:</Details>
                                    <InputTag type='text' name='modoPago' placeholder='Transferencia / Efectivo' {...register("modoPago", { required: true })}></InputTag>
                                </InputBox>
                                <InputBox>
                                    <Details>Método de pago:</Details>
                                    <InputTag type='text' name='metodoPago' placeholder='Contado / 3 cuotas / 6 cuotas' {...register("metodoPago", { required: true })}></InputTag>
                                </InputBox>
                            </UserDetails>
                            <InputDescripcion>
                                <Details>Observaciones:</Details>
                                <InputTexArea type='text' name='observaciones' placeholder='Observaciones' {...register("observaciones", { required: true })}></InputTexArea>
                            </InputDescripcion>
                            <hr></hr>

                            <Subtitle> <li type="square"> Documentación general del Solicitante: </li></Subtitle>
                            <UserDetails style={{ paddingLeft: '15px', paddingLeft: '15px' }}>
                                <ImageUpload
                                    label="Adjuntar DNI Frente:"
                                    id="dniFrente"
                                    typeImagen="dniFrente"
                                    mostrarImagen={mostrarImagen}
                                    capturarImagen={capturarImagen}
                                />
                                <ImageUpload
                                    label="Adjuntar DNI Dorso:"
                                    id="dniDorso"
                                    typeImagen="dniDorso"
                                    mostrarImagen={mostrarImagen}
                                    capturarImagen={capturarImagen}
                                />
                                <ImageUpload
                                    label="Adjuntar Datos de Respaldo:"
                                    id="respaldo"
                                    typeImagen="respaldo"
                                    mostrarImagen={mostrarImagen}
                                    capturarImagen={capturarImagen}
                                />
                                {/* <InputBox>
                            <Details><b>Adjuntar DNI Dorso:</b></Details>
                            <InputType type="file" name="dniDorso" value={campos.dniDorso}  onChange={onChange}></InputType>
                        </InputBox>
                        <InputBox>
                            <Details><b>Adjuntar Datos de Respaldo:</b></Details>
                            <InputType type="file" name="respaldo" value={campos.respaldo}  onChange={onChange} multiple></InputType>
                        </InputBox> */}
                            </UserDetails>
                            <ButtonGroup style={{ margin: '15px 10px 0px 10px' }}>
                                <ButtonBox2 style={{ margin: 'auto', padding: '10px' }}>
                                    <TextButton style={{ marginBottom: '0px' }} type='submit'> <b>ENVIAR SOLICITUD</b> </TextButton>
                                    <InputTypeSend type='submit' />
                                </ButtonBox2>
                            </ButtonGroup>

                        </StyledForm>
                    </Wrapper>
                </Container>
            </BodyContainerAllComponent>
        </>
    )
}

export default CrearSolicitud;