import React, { useState } from "react";
// import { Button } from "rsuite";
import { Button } from "react-bootstrap";
import { SideBar } from "../SideBar/SideBar";
import ReciboToPDF from "./ReciboToPDF/ReciboToPDF";
import styles from './styles/DocumentosPDF.module.css';

export const DocumentosPDF = () => {

    const [documento, setDocumento] = useState(null);

    // const [recibos, setRecibos] = useState(false);
    // const [reservas, setReservas] = useState(false);
    // const [cotizacionDirecta, setCotizacionDirecta] = useState(false);
    // const [cotizacionInmobilaria, setCotizacionInmobiliaria] = useState(false);
    // const [contratos, setContratos] = useState(false);


    return (
        <>
            <SideBar />
            <div className={styles.container}>
                <div className={styles.containerButtons}>
                    <Button
                        onClick={() => setDocumento('Recibos')}
                        href='/recibo_pdf'
                        className={styles.botonAzul}
                        aria-controls="example-collapse-text"
                    >
                        Recibos
                    </Button>
                    <Button
                        onClick={() => setDocumento('Reservas')}
                        href='/reservas_pdf'
                        className={styles.botonAzul}
                        aria-controls="example-collapse-text"
                    >
                        Reservas
                    </Button>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <Button
                            onClick={() => setDocumento('Directa')}
                            href='/cotizacion_directa_pdf'
                            className={styles.botonAzul}
                            aria-controls="example-collapse-text"
                        >
                            Cotización <br></br>
                            <div style={{ fontSize: '10px' }}>(Compra directa)</div>
                        </Button>
                        <Button
                            onClick={() => setDocumento('Inmobiliaria')}
                            href='/cotizacion_inmobiliaria_pdf'
                            className={styles.botonAzul}
                            aria-controls="example-collapse-text"
                        >
                            Cotización <br></br>
                            <div style={{ fontSize: '10px' }}>(Compra inmobiliaria)</div>
                        </Button>
                    </div>
                    <Button
                        onClick={() => setDocumento('Contratos')}
                        href='/contratos_pdf'
                        className={styles.botonAzul}
                        aria-controls="example-collapse-text"
                    >
                        Contratos
                    </Button>
                </div>
            </div>
        </>
    )
}