const arrResponsables = [
  "Filtrar por Responsable",
  "Victoria",
  "Evelyn",
  "Francisco",
  "Melisa",
  "Sol",
];

export default arrResponsables;
