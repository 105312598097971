import { actionTypes } from "../constants/ActionTypes";

const initialState = {
  inmobiliarias: [],
  estadistica: [],
  // inmosBuscadas: [],
  error: false,
};

export default function rootReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_INMOBILIARIA_ADMIN:
      const inmosOrdenadas = action.payload.sort((a, b) => b.orden - a.orden);

      return {
        ...state,
        inmobiliarias: inmosOrdenadas,
      };

    case actionTypes.EDIT_INMOBILIARIA:
      return {
        ...state,
      };

    case actionTypes.GET_ESTADISTICA_ADMIN:
      return {
        ...state,
        estadistica: action.payload,
      };

    case actionTypes.SET_ESTADISTICA_ADMIN:
      return {
        ...state,
        estadistica: action.payload,
      };

    case actionTypes.SET_ELIMINAR_INMOBILIARIA:
      const newArrInmos = state.inmobiliarias.filter(
        (inmo) => inmo.estado === true
      );
      return {
        ...state,
        inmobiliarias: newArrInmos,
      };

    case actionTypes.DISPLAY_ERROR:
      return {
        ...state,
        error: true,
      };

    default:
      return state;
  }
}
