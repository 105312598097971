import React from "react";
import { Details, ImageContainer, InputBox, Overlay } from "../CrearSolicitud/styledSolicitud";
import { useForm } from "react-hook-form";
const ImageUpload = ({ label, id, typeImagen, mostrarImagen, capturarImagen }) => {

  const {
    register,
    reset,
    formState: { errors, isValid },
    handleSubmit
} = useForm({
    mode: "onChange"
});

  return (
    <InputBox>
      <Details><b>{label}</b></Details>
      <Details htmlFor={id} style={{ cursor: "pointer" }}>
        <ImageContainer>
          <img alt={mostrarImagen[typeImagen]} src={mostrarImagen[typeImagen]} />
          <Overlay>
            <p>Haz click para subir la imagen</p>
          </Overlay>
        </ImageContainer>
      </Details>
      <input
        name="imagenDePerfil"
        style={{ display: "none" }}
        {...register("observaciones", { required: true })}
        onChange={e => capturarImagen(e, typeImagen)}
        id={id}
        type="file"
        accept="image/png, image/gif, image/jpeg"
      />
    </InputBox>
  );
};

export default ImageUpload;
