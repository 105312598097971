import styled from "styled-components";
import {AiOutlineArrowLeft} from 'react-icons/ai';
import {AiOutlinePaperClip} from 'react-icons/ai';
import { Link } from "react-router-dom";
import { SelectPicker } from 'rsuite';

export const Container = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   align-content: center;
   background: #FFF;
   color: #335998;
   margin-top: 30px;
   margin-bottom: 40px;

`
export const Wrapper = styled.div`
    width: 90%;
    height: 750px;
    background: #fff;
    padding: 25px 30px;
    border-radius: 25px;

    max-height: 800px;
    overflow-y: scroll;
`
export const WrapperCosolicitante = styled.div`
    background: #fff;
    border-radius: 25px;
`
export const Hero = styled.div`
    display: flex;
`
export const Title = styled.h2`
    color: rgb(1, 87, 154) !important;
    margin: 10px 0 20px 0;
    margin-left: 20px;
`
export const Subtitle = styled.h4`
    margin: 20px 0 12px 0;
    font-size: 1.3em;
    font-weight: 600;
    color: #0c5b9e;
`
export const StyledForm = styled.form`
    
` 
export const UserDetails = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
` 
export const InputBox = styled.div`
    margin: 20px 0 12px 0;
    width: calc(100% / 2 - 20px);
    position: relative;
` 
export const InputDescripcion = styled.div`
    margin: 20px 0 12px 18px;
    /* width: calc(100% / 2 - 20px); */
    position: relative;
` 
export const Details = styled.label`
    font-weight: 500;
    color: #335998;
    margin-bottom: 10px;
    display: block;
    width: 300px;
`


export const Details2 = styled.label`
    font-weight: 500;
    color: #335998;
    margin-bottom: 10px;
    display: block;
    width: 50px;
    border-radius: 50%;
`

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  &:hover {
    opacity: 1;
  }
    & > img {
        max-width: 300px;
        object-fit: cover;
    }
`
export const Overlay = styled.div`
position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    opacity: 1;
  }
  & > p {
      color: #004994;
      background-color: #fff;
      font-size: 18px;
      padding: 0 10px;
      border-radius: 15px;
    cursor: pointer;
  }
`

export const Overlay2 = styled.div`
position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &:hover {
    opacity: 1;
  }
  & > p {
      color: #004994;
      background-color: #fff;
      font-size: 18px;
      padding: 0 10px;
      border-radius: 15px;
    cursor: pointer;
  }
`

export const InputTag = styled.input`
    height: 45px;
    width: 90%;
    border-radius: 54px;
    border: none;
    background: #e7e7e7;
    font-weight: 400;
    padding-left: 15px;
    outline: none;
    -webkit-appearance: none;
    margin: 0;
     &::-webkit-inner-spin-button,
     &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    }
`

export const InputTexArea = styled.textarea`
    height: 150px;
    width: 90%;
    border-radius: 15px;
    padding-top: 10px;
    border: none;
    background: #e7e7e7;
    font-weight: 400;
    padding-left: 15px;
    outline: none;
    -webkit-appearance: none;
    margin: 0;
     &::-webkit-inner-spin-button,
     &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    }
`


export const SelecTag = styled.select`
    margin-top: -8px;
    height: 42px;
    width: 50%;
    border-radius: 54px;
    border: none;
    padding-left: 15px;
    outline: none;
    font-weight: 600;
`

export const SelecTag2 = styled(SelectPicker)`
    margin-top: -8px !important;
    height: 42px !important;
    width: 90% !important;
    border-radius: 54px !important;
    border: none !important;
    background: #e7e7e7 !important;
    /* padding-left: 15px !important; */
    outline: none !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    .rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-md{
         background: #e7e7e7 !important;
    }
`

export const SelecTag22 = styled.select`
    margin-top: -8px !important;
    height: 42px !important;
    width: 90% !important;
    border-radius: 54px !important;
    border: none !important;
    background: #e7e7e7 !important;
    padding-left: 15px !important;
    outline: none !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    .rs-picker-toggle.rs-btn.rs-btn-default.rs-btn-md{
         background: #e7e7e7 !important;
    }
`

export const Nota = styled.img`
    width: 1.6rem;
`
export const Route = styled(Link)`
    width: 45px;
    margin-top: 20px;
`
export const ArrowLeft = styled(AiOutlineArrowLeft)`
    display: block;
    fill: #57C1E3;
    font-size: 30px;
`
export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start; 
    //margin: 25px 0 25px 0;  
`
export const ButtonBox = styled.div`
    padding: 8px;
    width: 215px;
    // background: #0C5B9E;
    border-radius: 35px;
    position: relative;
`
export const ButtonBox2 = styled.div`
    padding: 8px;
    width: 215px;
    background: #0C5B9E;
    border-radius: 35px;
    position: relative;
`

export const InputType = styled.input`

    cursor: pointer;
`

export const InputTypeSend = styled.input`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
`

export const Text = styled.p`
    color: #fff;
    margin-left: 30px;
    text-align: initial;
    font-size: 12px;
`

export const ContButton = styled.div`
    text-align: right;
        display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

export const ModalBtn = styled.div`
    text-align: center;
`

export const TextBtn = styled.button`
    width: 240px;
    border-radius: 54px;
    padding: 10px;
    margin: 2px;
    text-align: center;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    background-color: #0c5b9e;
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
export const TextBtnDelete = styled.button`
    width: auto;
    border-radius: 54px;
    padding: 10px;
    margin: 2px;
    text-align: center;
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 800;
    background-color: #0c5b9e;
        display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`

export const Clip = styled(AiOutlinePaperClip)`
    font-size: 25px;
    position: absolute;
    color: #fff;
`
export const WrapperWarranty = styled.div`
    display: flex;
    justify-content: center; 
`
export const WarrantyBox = styled.div`
    background: #57C1E3;
    border-radius: 35px;
    position: relative;
    margin-top: 15px;
`
export const TextWarranty = styled.span`
    color: #fff;
    margin-left: 25px;
    padding-right: 10px;  
`
export const InputWarranty = styled.input`
    height: 45px;
    border-radius: 0px 40px 40px 0px;
    border: 1px solid rgba(1,87,154,0.31);
    padding-left: 15px;
    outline: none;
`
export const WrapperText = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
    flex-direction: column; 
`
export const TextCuento = styled(Link)`
    color: #57C1E3;
    text-align: center;
`
export const TextButton = styled.p`
    color: #fff;
    text-align: center;
`