import React from 'react';
import '../AdminHome/index.css';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';
// import CalculadorPagoUnico from './Calculador/PagoUnico/CalculadorPagoUnico';
import { SideBar } from '../../SideBar/SideBar';

export const BodyContainerAllComponent = styled.div`
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  @media (min-width:1024px) and (max-width: 1520px) {
    width: 75%;
  }
` 
function AdminHome () { 
  
  return (
    <>
      <SideBar/>
      <BodyContainerAllComponent
        widthInicio={true}
      >
      {/* <CalculadorPagoUnico/> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
        {/* Same as */}
      <ToastContainer />
      </BodyContainerAllComponent>
    </>
  );
}

export default AdminHome;
