import React from "react";
import style from "../styles/CotizacionDirectaToPDF.module.css";
import style2 from "../../ReservaToPDF/styles/InputsReserva.module.css";

const Input = ({
  id,
  text,
  name,
  currencyMask,
  handleChangeFormCotizacion,
  route,
}) => {
  return (
    <div
      key={id}
      className={
        route === "/reservas_pdf" || route === "/recibo_pdf"
          ? style2.containerInputReserva
          : style2.containerInputCotizacion
      }
    >
      <label className={style2.titleInput}>{text}</label>
      {name === "observaciones" ? (
        <textarea
          className={style2.inputReserva}
          style={{ height: "120px", width: "390px" }}
          name={name}
          onChange={(e) => {
            handleChangeFormCotizacion(e);
          }}
        ></textarea>
      ) : (
        <input
          className={
            route === "/reservas_pdf" || route === "/recibo_pdf"
              ? style2.inputReserva
              : style2.input
          }
          name={name}
          onInput={
            name === "alquiler" ||
            name === "expensas" ||
            name === "valorCuotasIncendio" ||
            name === "reserva" ||
            name === "garantia" ||
            name === "monto" ||
            name === "dni" ||
            name === "total" ||
            name === "nroContrato" ||
            name === "dniCogarante1" ||
            name === "dniCogarante2"
              ? currencyMask
              : null
          }
          onChange={(e) => {
            handleChangeFormCotizacion(e);
          }}
        ></input>
      )}
    </div>
  );
};

export default Input;
