import React, { useEffect } from "react";
import CustomFecha from "../CustomFecha/CustomFecha";
import styles from "./styles/InputsReserva.module.css";
import { TrustFooter, Firma } from "../ReciboToPDF/styles/assets";
import CustomFooter from "../CustomFooter/CustomFooter";

const DocumentoReserva = ({ inputs }) => {

  const {
    nombre,
    dni,
    dia,
    mes,
    año,
    domicilio,
    garantia,
    alquiler,
    expensas,
    reserva,
    cuotas,
    monto,
    diaVig,
    mesVig,
    añoVig,
  } = inputs;

  return (
    <div className={styles.extraContenido}>
      <div id="contenido" className={styles.contenido}>
        <div id="item" className={styles.item}>
          <div id="recibo" className={styles.recibo}>
            <div id="containerTextura" className={styles.containerTextura}>
              <div id="containerLogo" className={styles.containerLogo}>
                <img src={TrustFooter} alt="Imagen" className={styles.imagen} />
              </div>
              <div id="containerText" className={styles.containerText}>
                <div id="sideTitle" className={styles.sideTitle}>
                  <h2 className={styles.text}>
                    La garantía que <b>necesitás </b>
                    para vivir donde <b>querés.</b>
                  </h2>
                </div>
              </div>
            </div>
            <div id="containerDatos" className={styles.containerDatos}>
              <div id="headerDatos" className={styles.headerDatos}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "-webkit-fill-available",
                    justifyContent: "space-between",
                  }}
                >
                  <div id="titleDatos" className={styles.titleDatos}>
                    <h5 className={styles.recibo}>Reserva de Garantía</h5>
                  </div>
                  <CustomFecha dia={dia} mes={mes} año={año} />
                </div>
              </div>

              <div className={styles.nombreApellido}>
                <div className={styles.bodyForm}>
                  <p className={styles.parrafoReserva}>
                    Recibí de {nombre}, DNI {dni}, domicilio principal en{" "}
                    {domicilio}, la cantidad de ${reserva} en concepto de
                    anticipo para reserva de garantía para alquiler. La presente
                    reserva está sujeta a las siguientes cláusulas.
                  </p>
                  <p className={styles.parrafoReserva}>
                    1) El precio de la garantía se estipula en ${garantia}, para
                    un alquiler de ${alquiler} mensual al inicio del contrato de
                    locación, con expensas ordinarias de ${expensas}.
                  </p>
                  <p className={styles.parrafoReserva}>
                    2) Se establece como método de pago, una (1) primera cuota a
                    modo de anticipo de ${reserva} y el monto restante en{" "}
                    {cuotas} cuotas de ${monto} a abonarse del 1 al 5 de cada
                    mes. En caso de que la compra se realice abonando de contado
                    el monto restante deberá abonarse a Trust Fund al momento de
                    la firma de la garantía.<br></br>
                    <i>
                      *De incumplirse el plazo de pago de las cuotas se aplicará
                      1% de punitorios por día.
                    </i>
                  </p>
                  <p className={styles.parrafoReserva}>
                    3) Las variaciones en el monto del alquiler mensual inicial
                    o las expensas previstas, entre la fecha de reserva y la
                    firma de la garantía, se traducirán en la modificación del
                    costo de la garantía. En los casos en que la diferencia
                    resultase en un incremento del costo, el solicitante abonará
                    la diferencia. Si el valor fuese menor al previsto, Trust
                    Fund no realizará reembolso alguno.
                  </p>
                  <p className={styles.parrafoReserva}>
                    4) La presente reserva tendrá vigencia hasta el {diaVig} /{" "}
                    {mesVig} / {añoVig}. El solicitante, y los co-solicitantes
                    en el caso que correspondiere, se comprometen y obligan a
                    firmar el contrato de fianza en el plazo mencionado.
                  </p>
                  <p className={styles.parrafoReserva}>
                    5) En caso de incumplirse la cláusula N°2. 3 y/o N° 4, por
                    motivos ajenos a Trust Fund, el solicitante perderá el total
                    de la reserva y/o el pago total en caso de haber cancelado
                    la reserva en un solo pago. Asimismo, en caso de obligarse
                    al pago de la garantía en cuotas, deberá abonar EL TOTAL de
                    las mismas. Todo ello, bajo apercibimiento de iniciar las
                    acciones judiciales correspondientes.
                  </p>
                  <p className={styles.parrafoReserva}>
                    En prueba de conformidad se firman dos ejemplares de un
                    mismo tenor y efecto, en la ciudad de La Plata, el día {dia}{" "}
                    / {mes} / {año}
                  </p>
                </div>
                <div id="containerFirma" className={styles.containerFirma}>
                  <div className={styles.espacioFirma}>Firma solicitante</div>
                  <div className={styles.espacioFirma}>Firma inmobiliaria</div>
                  <img id="firma" src={Firma} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    </div>
  );
};

export default DocumentoReserva;
