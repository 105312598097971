import React, { useState } from "react";
import styles from "./styles/ContratoToPDF.module.css";
import InputsContrato from "./InputsContrato";
import DocumentoContrato from "./DocumentoContrato";
import { SideBar } from "../../SideBar/SideBar";
import ModalLoading from "./ModalLoading";

const ContratoToPDF = ({ route }) => {
  //Buttons
  const [buttonIzq, setButtonIzq] = useState("Comercio");
  const [buttonDer, setButtonDer] = useState("Vivienda");
  const [comercioVivienda, setComercioVivienda] = useState("");
  const [cobrante, setCobrante] = useState(false);
  const [cobrantes, setCobrantes] = useState(0);
  const [contadoCuotas, setContadoCuotas] = useState("");

  const [loading, setLoading] = useState(false);

  const [formContrato, setFormContrato] = useState({
    diaInput: "",
    mesInput: "",
    añoInput: "",

    nroContrato: "",

    nombreApellido: "",
    dni: "",
    domicilio: "",
    mail: "",

    calle: "",
    garantia: "",
    porcentajePrimerCuota: "",
    porcentajeRestante: "",
    cantidadCuotas: "",
    cantidadCuotasLetras: "",
    mes: "",
    mesesVigencia: "",
    alquiler: "",
    alquilerLetras: "",
    expensas: "",

    nombreCogarante1: "",
    nombreCogarante2: "",
    dniCogarante1: "",
    dniCogarante2: "",
    domicilioCogarante1: "",
    domicilioCogarante2: "",
    mailCogarante1: "",
    mailCogarante2: "",

    diaFirma: "",
    mesFirma: "",
    añoFirma: "",
  });

  const handleButton = (value) => {
    switch (value) {
      case "Comercio":
        setButtonIzq("Sin Cobrante");
        setButtonDer("Con Cobrante");
        setComercioVivienda("Comercio");
        break;
      case "Vivienda":
        setButtonIzq("Sin Cobrante");
        setButtonDer("Con Cobrante");
        setComercioVivienda("Vivienda");
        break;
      case "Sin Cobrante":
        setButtonIzq("Contado");
        setButtonDer("Cuotas");
        setCobrante(false);
        break;
      case "Con Cobrante":
        setButtonIzq("1");
        setButtonDer("2");
        setCobrante(true);
        break;
      case "1":
        setButtonIzq("Contado");
        setButtonDer("Cuotas");
        setCobrantes(1);
        break;
      case "2":
        setButtonIzq("Contado");
        setButtonDer("Cuotas");
        setCobrantes(2);
        break;
      case "Contado":
        setContadoCuotas("Contado");
        break;
      case "Cuotas":
        setContadoCuotas("Cuotas");
        break;
      case "default":
        break;
    }
  };

  return (
    <>
      <SideBar />
      <div
        style={{
          display: "flex",
          width: "-webkit-fill-available",
          justifyContent: "space-between",
          flexDirection: 'column'
        }}
      >
        <InputsContrato
          setFormContrato={setFormContrato}
          formContrato={formContrato}
          handleButton={handleButton}
          buttonIzq={buttonIzq}
          buttonDer={buttonDer}
          cobrante={cobrante}
          cobrantes={cobrantes}
          contadoCuotas={contadoCuotas}
          nombreApellido={formContrato.nombreApellido}
          route={route}
          setLoading={setLoading}
          loading={loading}
        />
        {
          loading && <ModalLoading />
        }
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "-webkit-fill-available",
          }}
        >
          <div id="contenido" style={{ width: '50%', alignSelf: 'center' }} className={styles.contenido}>
            <DocumentoContrato
              props={{
                comercioVivienda,
                cobrante,
                cobrantes,
                contadoCuotas,
                formContrato,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ContratoToPDF;
