import React, { useState, useEffect } from "react";
import {
  Container,
  Wrapper,
  Hero,
  Hero1,
  Nota,
  Title,
  ModalBtn,
} from "../styledComponents/styledInmobiliariasAdheridas";
import "rsuite/dist/rsuite.min.css";
import RemindFillIcon from "@rsuite/icons/RemindFill";
import { useSelector, useDispatch } from "react-redux";
import { getInmobiliarias, setEliminarInmobiliaria } from "../../actions";
import headers from "./headers";

// MODALS
import ModalInfoInmobiliaria from "./ModalInfoInmobiliaria";

//TOOLBAR
import Toolbar from "./Toolbar";

// ICONS
import { Button, Modal } from "rsuite";
import InmoAdheridas from "../../images/icons/inmoadheridas.svg";

import { SideBar } from "../SideBar/SideBar";
import { ModalAgregarInmobiliaria } from "./ModalAgregarInmobiliaria";
import TablaInmobiliarias from "./TablaInmobiliarias";
import TablePagination from "./TablePagination";

export const InmobiliariasAdheridas = () => {
  const [modalView, setModalView] = useState();
  const [activePage, setActivePage] = useState(1);
  const [busqueda, setBusqueda] = useState("");
  const [filter, setFilter] = useState("nombre");
  const [responsables, setResponsables] = useState();

  // Guardo info a mostrar
  const dispatch = useDispatch();
  const [modalAgregarInmob, setModalAgregarInmob] = useState(false);
  const [modalEditarInmob, setModalEditarInmob] = useState(false);
  const [modalEliminarInmob, setModalEliminarInmob] = useState(false);

  const [render, setRender] = useState(true);

  const [changeVenta, setChangeVenta] = useState(false);
  const [editedVentasLocal, setEditedVentasLocal] = useState(null);

  useEffect(() => {
    dispatch(getInmobiliarias());
  }, [render]);

  const inmobiliarias = useSelector((state) => state.inmobiliarias);

  const inmosPerPage = 15;

  const startIndex = (activePage - 1) * inmosPerPage;

  const endIndex = startIndex + inmosPerPage;

  const inmosFiltradas = inmobiliarias.filter((i) => {
    if (filter === "nombre") {
      return i.nombre?.toLowerCase().indexOf(busqueda.toLocaleLowerCase()) > -1;
    }
    if (filter === "localidad") {
      return (
        i.localidad?.toLowerCase().indexOf(busqueda.toLocaleLowerCase()) > -1
      );
    }
    if (filter === "asesor") {
      return (
        i.asesorInmo?.toLowerCase().indexOf(busqueda.toLocaleLowerCase()) > -1
      );
    }
  });

  const currentInmos = inmosFiltradas?.slice(startIndex, endIndex);

  const [idEliminar, setIdEliminar] = useState("");

  const totalPages = Math.ceil(inmosFiltradas.length / inmosPerPage);

  function eliminarInmobiliaria(id) {
    dispatch(setEliminarInmobiliaria(id));
    setRender(!render);

    setTimeout(() => {
      dispatch(getInmobiliarias());
    }, 1500);
  }

  const handleClose = () => {
    setModalEliminarInmob(false);
  };

  return (
    <>
      <SideBar />
      <Container>
        <Wrapper>
          <Hero>
            <Hero1>
              <Nota src={InmoAdheridas} alt="" />
              <Title>Inmobiliarias / Asesores</Title>
            </Hero1>
          </Hero>
          <Hero>
            <Toolbar
              setBusqueda={setBusqueda}
              busqueda={busqueda}
              setFilter={setFilter}
              filter={filter}
              setResponsables={setResponsables}
              responsables={responsables}
              inmobiliarias={inmobiliarias}
              setModalAgregarInmob={setModalAgregarInmob}
              headers={headers}
              inmosFiltradas={inmosFiltradas}
              currentInmos={currentInmos}
            />
          </Hero>
          {
            <>
              <TablaInmobiliarias
                busqueda={busqueda}
                inmosFiltradas={inmosFiltradas}
                currentInmos={currentInmos}
                changeVenta={changeVenta}
                setChangeVenta={setChangeVenta}
                editedVentasLocal={editedVentasLocal}
                setEditedVentasLocal={setEditedVentasLocal}
                setModalEditarInmob={setModalEditarInmob}
                setModalView={setModalView}
                setIdEliminar={setIdEliminar}
                setModalEliminarInmob={setModalEliminarInmob}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <TablePagination
                  totalItems={totalPages}
                  activePage={activePage}
                  setActivePage={setActivePage}
                  busqueda={busqueda}
                  responsables={responsables}
                />
              </div>
            </>
          }
        </Wrapper>
      </Container>

      {modalEditarInmob && (
        <ModalInfoInmobiliaria
          dataModalView={modalView}
          setModalView={setModalView}
          setModalEditarInmob={setModalEditarInmob}
          setRender={setRender}
          render={render}
        />
      )}
      {modalAgregarInmob && (
        <ModalAgregarInmobiliaria
          setModalAgregarInmob={setModalAgregarInmob}
          setRender={setRender}
          render={render}
          inmobiliaria={inmobiliarias}
        />
      )}
      {modalEliminarInmob && (
        <div className="modal-container">
          <Modal size={"lg"} open={modalEliminarInmob} onClose={handleClose}>
            <Modal.Header>
              <ModalBtn>
                <Hero>
                  <Hero1 style={{ margin: "auto" }}>
                    <RemindFillIcon
                      style={{ fontSize: "30px", color: "red" }}
                    />
                    <Title style={{ marginTop: "0px" }}>
                      Esta seguro de eliminar la inmobiliaria?
                    </Title>
                  </Hero1>
                </Hero>
              </ModalBtn>
            </Modal.Header>
            <Modal.Body></Modal.Body>
            <Modal.Footer style={{ padding: "20px 35px 0px 0px" }}>
              <Button
                style={{
                  background: "#0C5B9E",
                  color: "white",
                  padding: "10px 25px 10px",
                  fontWeight: 900,
                }}
                onClick={() => {
                  eliminarInmobiliaria(idEliminar);
                  handleClose();
                  setRender(!render); /*uncheckAll()*/
                }}
                color="green"
                appearance="primary"
              >
                Eliminar
              </Button>
              <Button
                style={{
                  background: "#0C5B9E",
                  color: "white",
                  padding: "10px 25px 10px",
                  fontWeight: 900,
                }}
                onClick={handleClose}
                color="orange"
                appearance="primary"
              >
                Cancelar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </>
  );
};

export default InmobiliariasAdheridas;
