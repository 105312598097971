import React from "react";
import { Routes, Route, BrowserRouter } from 'react-router-dom';

import { Landing } from "./Components/Landing/Landing";
import { InmobiliariasAdheridas } from "./Components/InmobiliariasAdheridas/InmobiliariasAdheridas";
import ModalCalculador from "./Components/Simulador/ModalCalculador";
import CrearSolicitud from "./Components/CrearSolicitud/CrearSolicitud";
import { DocumentosPDF } from "./Components/DocumentosPDF/DocumentosPDF";
import ReciboToPDF from "./Components/DocumentosPDF/ReciboToPDF/ReciboToPDF";
import ReservaToPDF from "./Components/DocumentosPDF/ReservaToPDF/ReservaToPDF";
import CotizacionesToPDF from "./Components/DocumentosPDF/CotizacionesToPDF/CotizacionesToPDF";
import ContratoToPDF from './Components/DocumentosPDF/ContratoToPDF/ContratoToPDF';

export default function ControllerRoutes() {
    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Landing />}/>
                    <Route path='/' element={<InmobiliariasAdheridas/>} />
                    <Route path='/simulador_garantia' element={<ModalCalculador/>} />
                    <Route path='/crear_solicitud' element={<CrearSolicitud/>} />
                    <Route path='/dashboard_documentos' element={<DocumentosPDF/>} />
                    <Route path='/recibo_pdf' element={<ReciboToPDF route={'/recibo_pdf'} />} />
                    <Route path='/reservas_pdf' element={<ReservaToPDF route={'/reservas_pdf'}/>} />
                    <Route path='/cotizacion_directa_pdf' element={<CotizacionesToPDF route={'/cotizacion_directa_pdf'}/>} />
                    <Route path='/cotizacion_inmobiliaria_pdf' element={<CotizacionesToPDF route={'/cotizacion_inmobiliaria_pdf'}/>} />
                    <Route path='/contratos_pdf' element={<ContratoToPDF route={'/contratos_pdf'}/>} />
                </Routes>
            </BrowserRouter>
        </>
    )
}