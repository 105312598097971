import React from "react";
import ButtonsInputs from "../buttonsInputs/ButtonsInputs";
import Input from "../Input/Input";
import style from "../styles/CotizacionDirectaToPDF.module.css";

const InputSection = ({
  id,
  formCotizacion,

  buttonsPago,
  buttonsMoneda,
  handleClickPago,
  handleClickMoneda,

  inputs,
  titleSection,
  handleChangeFormCotizacion,
  currencyMask,
  route,
}) => {
  return (
    <div
      className={
        route === "/reservas_pdf"
          ? style.ContInputReserva
          : route === "/recibo_pdf"
          ? style.ContInputRecibo
          : style.ContInput
      }
    >
      <div className={style.ContainerInputsCliente}>{titleSection}</div>
      <div
        id={id}
        className={
          route === "/reservas_pdf"
            ? style.InputsClienteReserva
            : route === "/recibo_pdf"
            ? style.InputsClienteRecibo
            : style.InputsCliente
        }
        style={{ borderBottom: titleSection === "Total" && "none" }}
      >
        <div
          className={
            route === "/reservas_pdf" || route === "/recibo_pdf"
              ? style.InputsReserva
              : style.Inputs
          }
        >
          {inputs.map((i, index) => {
            if (Array.isArray(i)) {
              return (
                <div className={style.arrayInput} key={index}>
                  <Input
                    id={i[0].name && i[0].name}
                    text={i[0].text && i[0].text}
                    name={i[0].name && i[0].name}
                    currencyMask={currencyMask}
                    handleChangeFormCotizacion={handleChangeFormCotizacion}
                    route={route}
                  />

                  <Input
                    id={i[1].name && i[1].name}
                    text={i[1].text && i[1].text}
                    name={i[1].name && i[1].name}
                    currencyMask={currencyMask}
                    handleChangeFormCotizacion={handleChangeFormCotizacion}
                    route={route}
                  />
                </div>
              );
            } else {
              return (
                <Input
                  id={index}
                  text={i.text}
                  name={i.name}
                  currencyMask={currencyMask}
                  handleChangeFormCotizacion={handleChangeFormCotizacion}
                  route={route}
                  key={index}
                />
              );
            }
          })}
        </div>
        {route === "/cotizacion_directa_pdf" ||
        route === "/cotizacion_inmobiliaria_pdf" ||
        route === "/recibo_pdf" ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                borderRight: route === "/recibo_pdf" ? null : "1px solid",
              }}
            >
              {id === "calcGarantía" &&
                buttonsPago.map((button, index) => {
                  return (
                    <ButtonsInputs
                      text={button}
                      value={formCotizacion.modoDePago}
                      handleClick={handleClickPago}
                      key={index}
                    />
                  );
                })}
            </div>
            <div style={{ display: "flex" }}>
              {id === "calcGarantía" || id === "Total"
                ? buttonsMoneda.map((button, index) => {
                    return (
                      <ButtonsInputs
                        text={button}
                        value={formCotizacion.moneda}
                        handleClick={handleClickMoneda}
                        key={index}
                      />
                    );
                  })
                : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default InputSection;
