import { actionTypes } from "../constants/ActionTypes";
import axios from "axios";

// const url = process.env.REACT_APP_LOCAL_SERVER_URL;

const url = process.env.REACT_APP_PRODUCTION_SERVER_URL;

export const respuestaEliminarInmobiliaria = (data) => {
  return {
    type: actionTypes.RESPUESTA_ELIMINAR_INMOBILIARIA,
    data,
  };
};

//solicitudes
export const getSolicitudes = (data) => {
  return {
    type: actionTypes.DASHBOARD_ENVIA_GET_SOLICITUDES,
    data,
  };
};

export const setSolicitudes = (data) => {
  return {
    type: actionTypes.DASHBOARD_ENVIA_SET_SOLICITUDES,
    data,
  };
};

export const editar_solicitud = (data) => {
  return {
    type: actionTypes.EDITAR_SOLICITUD,
    data,
  };
};

export const editarAdmin = (data) => {
  return {
    type: actionTypes.EDITAR_SOLICITUD_ADMIN,
    data,
  };
};

export const getInmobiliarias = () => {
  return async (dispatch) => {
    try {
      await axios.get(`${url}/api/inmobiliarias_adheridas`).then((response) => {
        console.log("ESTE ES EL RESPONSE: ", response);
        return dispatch({
          type: actionTypes.GET_INMOBILIARIA_ADMIN,
          payload: response.data,
        });
      });
    } catch (error) {
      console.log("getInmobiliarias", error);
    }
  };
};

export const getInmobiliariasByResponsable = (value) => {
  return async (dispatch) => {
    try {
      await axios
        .get(`${url}/api/inmobiliarias_adheridas`, {
          params: { responsable: value },
        })
        .then((response) => {
          return dispatch({
            type: actionTypes.GET_INMOBILIARIA_ADMIN,
            payload: response.data,
          });
        });
    } catch (error) {
      console.log("getInmobiliarias", error);
    }
  };
};

export const editarInmo = (payload) => {
  return async (dispatch) => {
    try {
      await axios
        .put(`${url}/api/editar_inmobiliaria`, payload)
        .then((response) => {
          return dispatch({
            type: actionTypes.EDIT_INMOBILIARIA,
            payload: response.data,
          });
        });
    } catch (error) {
      console.log("editarInmo", error);
    }
  };
};

export const getEstadisticaAdmin = (data) => {
  return {
    type: actionTypes.GET_ESTADISTICA_ADMIN,
    data,
  };
};

export const setEstadisticaAdmin = (data) => {
  return {
    type: actionTypes.SET_ESTADISTICA_ADMIN,
    data,
  };
};

export const setInmobiliarias = (data) => {
  return {
    type: actionTypes.SET_INMOBILIARIA_ADMIN,
    data,
  };
};

export const sendFormData = async (data) =>
  fetch("/api/contact", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  }).then((res) => {
    if (!res.ok) throw new Error("Failed to send message");
    return res.json();
  });

export const agregarInmobiliaria = (data) => {
  console.log(data.input);

  return (dispatch) => {
    try {
      axios
        .post(`${url}/api/agregar_inmobiliaria`, data.input)
        .then((response) => {
          console.log(response.data);
        });
    } catch (error) {
      if (error.response && error.response.status === 400) {
        console.error("Bad Request:", error.response.data);
        return {
          type: actionTypes.DISPLAY_ERROR,
        };
      } else {
        console.error("An error occurred:", error.message);
      }
    }
  };
};

export const setEliminarInmobiliaria = (payload) => {
  return (dispatch) => {
    try {
      axios.put(`${url}/api/eliminarInmo`, payload).then((response) => {
        return dispatch({
          type: actionTypes.SET_ELIMINAR_INMOBILIARIA,
          payload: response,
        });
      });
    } catch (error) {
      console.log("setEliminarInmobiliaria", error);
    }
  };
};

export const editVentasInmo = (venta, id) => {
  return async (dispatch) => {
    try {
      let json = await axios.put(`${url}/api/editVentasInmo`, { venta, id });
      return json;
    } catch (error) {
      console.log("editVentasInmo", error);
    }
  };
};
// Actions Garantias

export const postGarantia = (data) => {
  return async (dispatch) => {
    try {
      await axios.post(`${url}/postGarantia/`, data);
    } catch (error) {
      console.log("handleVentas", error);
    }
  };
};
