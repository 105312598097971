import React from "react";
import styles from "./styles/InputsReserva.module.css";
import objectInputs from "./objectInputs";
import InputSection from "../CotizacionesToPDF/InputSection/InputSection";
import currencyMask from "../CotizacionesToPDF/Controllers/currencyMask";

export const InputsReserva = ({
  setStateReserva,
  stateReserva,
  generarPDF,
  route,
}) => {

  function handleChangeData(e) {
    const { name, value } = e.target;

    if (
      name === "garantia" ||
      name === "alquiler" ||
      name === "expensas" ||
      name === "reserva" ||
      name === "monto"
    ) {
      setStateReserva({
        ...stateReserva,
        [name]: value,
      });
    } else {
      setStateReserva({
        ...stateReserva,
        [name]: value,
      });
    }
  }

  return (
    <>
      <div className={styles.containerGral}>
        <div className={styles.subContainerGral}>
          <div id="titleDatos" className={styles.titleDatos}>
            <h5 className={styles.recibo}>Reserva de Garantía</h5>
          </div>
          <div className={styles.boxInputs}>
            {objectInputs.map((input) => {
              return (
                <InputSection
                  id={input.id}
                  formCotizacion={stateReserva}
                  inputs={input.inputs}
                  titleSection={input.titleSection}
                  handleChangeFormCotizacion={handleChangeData}
                  currencyMask={currencyMask}
                  route={route}
                  key={input.id}
                />
              );
            })}
          </div>
          <button onClick={() => generarPDF()} className={styles.buttonPDF}>
            Generar PDF
          </button>
        </div>
      </div>
    </>
  );
};
