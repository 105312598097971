const InputsForm = [
  {
    title: "Nombre Inmobiliaria",
    inputName: "nombre",
    required: true,
  },
  {
    title: "Asesor",
    inputName: "asesorInmo",
    required: true,
  },
  {
    title: "Teléfono",
    inputName: "telefono",
    required: true,
  },
  {
    title: "Responsable",
    inputName: "asesorTF",
    required: true,
    option: [
      "Elegír Asesor",
      "Victoria",
      "Evelyn",
      "Francisco",
      "Melisa",
      "Sol",
    ],
  },
  {
    title: "Provincia",
    inputName: "provincia",
    required: true,
    option: [
      "Elegír Provincia",
      "Buenos Aires",
      "Mendoza",
      "Córdoba",
      "La Pampa",
      "Santa Fé",
      "Santiago del Estero",
      "Corrientes",
      "Entre Ríos",
      "Misiones",
      "Tucuman",
      "Salta",
      "Jujuy",
      "Chaco",
      "Formosa",
      "San Juan",
      "Catamarca",
      "San Luís",
      "La Rioja",
      "Neuquén",
      "Río Negro",
      "Chubut",
      "Santa Cruz",
      "Tierra del Fuego",
    ],
  },
  {
    title: "Localidad",
    inputName: "localidad",
    required: true,
  },
  {
    title: "Domicilio (opcional)",
    inputName: "domicilio",
    required: false,
  },
  {
    title: "Web (opcional)",
    inputName: "web",
    required: false,
  },
  {
    title: "Mail (opcional)",
    inputName: "mail",
    required: false,
  },
  {
    title: "Comentario (opcional)",
    inputName: "comentario",
    required: false,
  },
];

export default InputsForm;
