
export const actionTypes = {
    DASHBOARD_RECIBE_GET_USER: 'DASHBOARD_RECIBE_GET_USER',
    DASHBOARD_RECIBE_SET_USER: 'DASHBOARD_RECIBE_SET_USER',
    DASHBOARD_ENVIA_POST_GARANTIA: 'DASHBOARD_ENVIA_POST_GARANTIA',
    DASHBOARD_ENVIA_RES_GARANTIA: "DASHBOARD_ENVIA_RES_GARANTIA",
    DASHBOARD_ENVIA_GET_SOLICITUDES: 'DASHBOARD_ENVIA_GET_SOLICITUDES',
    DASHBOARD_ENVIA_SET_SOLICITUDES: 'DASHBOARD_ENVIA_SET_SOLICITUDES',
    EDITAR_SOLICITUD: 'EDITAR_SOLICITUD',
    GET_TODOS_ESTADOS_GARANTIA: 'GET_TODOS_ESTADOS_GARANTIA',
    SET_TODOS_ESTADOS_GARANTIA: 'SET_TODOS_ESTADOS_GARANTIA',
    DASHBOARD_ENVIA_POST_COSOLICITANTE: 'DASHBOARD_ENVIA_POST_COSOLICITANTE',
    ENVIO_PROVINCIAS_LOCALIDADES:'ENVIO_PROVINCIAS_LOCALIDADES',
    ENVIO_PROVINCIAS_LOCALIDADES_COSOLICITANTE:'ENVIO_PROVINCIAS_LOCALIDADES_COSOLICITANTE',
    GET_INMOBILIARIA_ADMIN: 'GET_INMOBILIARIA_ADMIN',
    BUSCAR_INMOS: 'BUSCAR_INMOS',
    EDIT_INMOBILIARIA: 'EDIT_INMOBILIARIA',
    DISPLAY_ERROR: 'DISPLAY_ERROR',
    SET_INMOBILIARIA_ADMIN: 'SET_INMOBILIARIA_ADMIN',
    GET_ASESORES_ADMIN: 'GET_ASESORES_ADMIN',
    SET_ASESORES_ADMIN: 'SET_ASESORES_ADMIN',
    GET_REGISTROS_ADMIN: 'GET_REGISTROS_ADMIN',
    SET_REGISTROS_ADMIN: 'SET_REGISTROS_ADMIN',
    GET_SOLICITUDES_ADMIN: 'GET_SOLICITUDES_ADMIN',
    SET_SOLICITUDES_ADMIN: 'SET_SOLICITUDES_ADMIN',
    GET_IDS_IMG_GARANTIA: 'GET_IDS_IMG_GARANTIA',
    SET_IDS_IMG_GARANTIA: 'SET_IDS_IMG_GARANTIA',
    LIMPIAR_IDS_IMG_GARANTIA: 'LIMPIAR_IDS_IMG_GARANTIA',
   

    EDITAR_SOLICITUD_ADMIN:'EDITAR_SOLICITUD_ADMIN',
    GET_ESTADISTICA_ADMIN: 'GET_ESTADISTICA_ADMIN',
    SET_ESTADISTICA_ADMIN: 'SET_ESTADISTICA_ADMIN',
    SET_ESTADO_USER:'SET_ESTADO_USER',

      //ELIMINAR POST
    RESPUESTA_ELIMINAR_SOLICITUD: "RESPUESTA_ELIMINAR_SOLICITUD",
    SET_ELIMINAR_SOLICITUD: "SET_ELIMINAR_SOLICITUD",
      //ELIMINAR ASESOR
    RESPUESTA_ELIMINAR_ASESOR:"RESPUESTA_ELIMINAR_ASESOR",
    SET_ELIMINAR_ASESOR:"SET_ELIMINAR_ASESOR",
    
     //ELIMINAR INMOBILIARIA

    SET_ELIMINAR_INMOBILIARIA:"SET_ELIMINAR_INMOBILIARIA",  
    RESPUESTA_ELIMINAR_INMOBILIARIA:"RESPUESTA_ELIMINAR_INMOBILIARIA"
}